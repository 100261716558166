import waRequest from './wa-request';

export const apiBase = '/communication-history/v1';

/**
 *
 * @typedef {{organizationId: string, locale: string, page: number, size: integer, sort: string}} PageableCommunicationParams
 *
 * @typedef {{organizationId: string, locale: string}} CommunicationParams
 *
 * @typedef {{type: string, organizationId: string, locale: string, sort: string}} CommunicationReportParams
 *
 * @typedef {Object} CommunicationSearchFilter
 * @property {string[]} channels The media channels to filter on. example: List [ "EMAIL" ]
 * @property {string} dateEnd The date time to end the search from. Must be < 60 days from now. example: 2023-01-03T10:15:30+01:00
 * @property {string} dateStart The date time to start the search from. Must be < 60 days from now. example: 2023-01-01T10:15:30+01:00
 * @property {string} direction One of: [UNKNOWN, INBOUND, OUTBOUND], The direction to filter on.
 * @property {string} organizationId example: 00000000-0000-0000-0000-000000000000 The organization id to filter on, returns comms for it and its sub-orgs. Either this or personId (where available) must be passed.
 * @property {string} personId The recipient OR sender person id to filter on when returning comms. Either this or organizationId must be passed.
 * @property {string} recipientLike Maximum length: 256, Minimum length: 3, example: crisis24, Find recipients that are LIKE %value%.
 * @property {string} senderLike Maximum length: 256, Minimum length: 3, example: crisis24, Find senders that are LIKE %value%.
 * @property {string} subjectLike Maximum length: 256, Minimum length: 3, example: account, Find subjects that are LIKE %value%.
 * @property {string[]} types One or more of: [UNKNOWN, ACCOUNT_ACTIVATION, PASSWORD_RECOVERY, EMAIL_USERNAME_CHANGE, ALERT_SUBSCRIPTION_EXPIRED, ALERT, DIB, RATING_NOTIFICATION, SECURITY_OUTLOOK, PEOPLE_UPLOAD_CONFIRMATION, THREAT_ZONE, MOBILE_CHECK_IN, TRIP_BOOKING, MULTI_TRAVELER_NOTIFICATION, NON_PREF_AIRLINE_NOTIFICATION, PRE_TRAVEL_NOTIFICATION, TRAVEL_ALERT, DIRECT_MESSAGE, TRIP_UPLOAD_COMPLETE, INCIDENT_EXPIRY_NOTIFICATION, INCIDENT, PRE_EXPATRIATION_NOTIFICATION, EXPATRIATION_SCHEDULED_NOTIFICATION, EXPATRIATION_COMPLETED_NOTIFICATION, EXPATRIATION_ALERT, SITES_UPLOAD_CONFIRMATION, ANALYSIS, ELEARNING_COMPLETION_NOTIFICATION, TRAVELER_CHECK_IN_NOTIFICATION_RESPONSE_SUMMARY, TRAVELER_CHECK_IN_NOTIFICATION_NEEDS_HELP, TRAVELER_CHECK_IN_NOTIFICATION_NO_RESPONSE, IN_APP_MESSAGE_NOTIFICATION, EXPOSURE_CHECK_NOTIFICATION, CRISIS_SIGNAL_CONFIRMATION, SAFETY_CHECK_IN_CONFIRMATION] The specific communication types to return.
 * @property {string} view One of: [UNKNOWN, ACCOUNT, INTELLIGENCE, MOBILE_CHECK_IN, TRIP, TRAVEL_ALERT, DIRECT_MESSAGE, EXPATRIATION, EXPATRIATION_ALERT, E_LEARNING, TRAVELER_CHECK_IN_NOTIFICATION, IN_APP_MESSAGE_NOTIFICATION] The view of communication to return.
 */

/**
 * Initiate authenticated POST ${apiBase}/communications/searches
 * @param {PageableCommunicationParams} urlParams
 * @param {CommunicationSearchFilter} filter
 * @param {AbortSignal} signal use to cancel request
 * @returns {Promise}
 */
function getListOfCommunications(urlParams, filter, signal) {
  const url = `${apiBase}/communications/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    signal
  });
}

function getListOfCommunicationsWrapper({
  params: urlParams,
  data: filter,
  signal
}) {
  return getListOfCommunications(urlParams, filter, signal)
}

/**
 * Initiate authenticated GET ${apiBase}/communications/{id}
 * @param {string} communicationId
 * @param {CommunicationParams} urlParams
 * @returns {Promise}
 */
function getCommunication(communicationId, urlParams) {
  const url = `${apiBase}/communications/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

function getCommunicationConfigPattern({pathParams: communicationId, params: {orgId, locale, relatedIntel=false, signal}}) {
  const url = `${apiBase}/communications/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      organizationId: orgId,
      locale,
      relatedIntel
    }
  });
}

/**
 * Initiate authenticated GET ${apiBase}/communications/{id}/reports
 * @param {string} communicationId
 * @param {CommunicationReportParams} urlParams
 * @returns {Promise}
 */
function getReportOfCommunication(communicationId, urlParams) {

  const url = `${apiBase}/communications/${communicationId}/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams,
    responseType: 'blob'
  });
}

function getCommunicationBody(config) {

  const {
    pathParams: communicationId,
    params: {
      orgId,
      locale,
    },
    signal
  } = config;

  const url = `${apiBase}/communications/${communicationId}/body`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      organizationId: orgId,
      locale
    }
  });
}

/**
 * Initiate authenticated GET ${apiBase}/communications/{id}/recipients
 * @param {string} communicationId
 * @param {PageableCommunicationParams} urlParams
 * @returns {Promise}
 */
function getRecipientsOfCommunication(communicationId, urlParams) {

  const url = `${apiBase}/communications/${communicationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

/**
 *
 * Gets the recipients that were notified about a site being impacted by intel (alert or incident).
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string[]} config.params.status One or more of: [UNKNOWN, SENT, DELIVERED, FAILED]. Filter recipients by status.
 * @param {string} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {string=} [config.params.locale=en] The requested language to return data in. ISO 639-1 format.
 * @param {number=} [config.params.page=0] Zero-based page index (0..N)
 * @param {number=} [config.params.size=100] The size of the page to be returned
 * @param {string=} config.params.sort Sorting criteria in the format: field(,asc|desc).
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.siteId The id of a site
 * @param {string} config.pathParams.intelId The id of an intel entity, e.g. alert id or incident id
 * @param {AbortSignal} config.signal use to cancel request
 *
 * @returns {Promise}
 * */
function getCommunicationsAlertHistoryRecipients(config) {

  const {
    signal,
    params: {
      organizationId
    },
    pathParams: {
      siteId,
      intelId
    }
  } = config;

  const url = `${apiBase}/communications/sites/${siteId}/intel/${intelId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      organizationId
    }
  });
}


/**
 * Initiate authenticated POST ${apiBase}/check-in-notifications/searches
 * @param {PageableCommunicationParams} urlParams
 * @param {CommunicationSearchFilter} filter
 * @returns {Promise}
 */
function getListOfMobileCheckins(urlParams, filter) {

  const url = `${apiBase}/check-in-notifications/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams
  });
}

/**
 * Initiate authenticated POST ${apiBase}/check-in-notifications/reports
 * @param {CommunicationReportParams} urlParams
 * @param {CommunicationSearchFilter} filter
 * @returns {Promise}
 */
function getReportOfListOfMobileCheckins(urlParams, filter) {
  const url = `${apiBase}/check-in-notifications/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    responseType: 'blob'
  });
}

/**
 * Initiate authenticated GET ${apiBase}/check-in-notifications/{id}
 * @param {string} communicationId
 * @param {CommunicationParams} urlParams
 * @returns {Promise}
 */
function getMobileCheckin(communicationId, urlParams) {

  const url = `${apiBase}/check-in-notifications/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

/**
 * Initiate authenticated GET ${apiBase}/check-in-notifications/{id}/reports
 * @param {string} communicationId
 * @param {CommunicationReportParams} urlParams
 * @returns {Promise}
 */
function getReportOfMobileCheckin(communicationId, urlParams) {

  const url = `${apiBase}/check-in-notifications/${communicationId}/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams,
    responseType: 'blob'
  });
}

/**
 * Initiate authenticated GET ${apiBase}/check-in-notifications/{id}/recipients
 * @param {string} communicationId
 * @param {PageableCommunicationParams} urlParams
 * @returns {Promise}
 */
function getRecipientsOfMobileCheckin(communicationId, urlParams) {

  const url = `${apiBase}/check-in-notifications/${communicationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

function getRollupSearches(urlParams, filter) {
  const url = `${apiBase}/rollups/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams
  });
}

function getReportOfRollupSearches(urlParams, filter) {
  const url = `${apiBase}/rollups/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: filter,
    params: urlParams,
    responseType: 'blob'
  });
}

function getRollupMessageDetails(communicationId, urlParams) {
  const url = `${apiBase}/rollups/${communicationId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

function getRollupMessageRecipients(communicationId, urlParams) {
  const url = `${apiBase}/rollups/${communicationId}/recipients`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams
  });
}

/**
 * @deprecated use /rollups/{id}/exports
 *
 * */
function getRollupMessageReport(communicationId, urlParams) {

  const url = `${apiBase}/rollups/${communicationId}/reports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: urlParams,
    responseType: 'blob'
  });
}

function getCommunicationSubTypes({view, locale}) {

  const url = `${apiBase}/communications/options`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale,
      view
    }
  });
}

/**
 * Creates a new saved search
 *
 * @param {Object} config
 * @param {Object} config.params  Querystring parameters
 * @param {string<uuid>} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {Object} config.data Body of request
 * @param {string=} config.data.dateRangeType One of: [STATIC, RELATIVE, NONE] The Date Range Type. Required for create requests and when the filter is provided for update requests.
 * @param {string=} config.data.description Maximum length: 75, example: List of account notifications from the last 6 months. The saved-search description
 * @param {CommunicationSearchFilter} config.data.filter
 * @param {string}config.data.name Maximum length: 50, Minimum length: 3, example: Account Notifications. The saved-search name
 *
 * @returns {Promise}
 * */
function createSavedSearch(config) {

  const {
    params: {
      organizationId
    },
    data: {
      dateRangeType,
      description,
      filter,
      name
    }
  } = config;

  const url = `${apiBase}/communications/saved-searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    params: {
      organizationId
    },
    data: {
      dateRangeType,
      description,
      filter,
      name
    }
  })
}

/**
 * Updates an existing saved search
 *
 * @param {Object} config
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.id The saved search id
 * @param {Object} config.params  Querystring parameters
 * @param {string<uuid>} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {Object} config.data Body of request
 * @param {string=} config.data.dateRangeType One of: [STATIC, RELATIVE, NONE] The Date Range Type. Required for create requests and when the filter is provided for update requests.
 * @param {string=} config.data.description Maximum length: 75, example: List of account notifications from the last 6 months. The saved-search description
 * @param {CommunicationSearchFilter} config.data.filter
 * @param {string}config.data.name Maximum length: 50, Minimum length: 3, example: Account Notifications. The saved-search name
 *
 * @returns {Promise}
 * */
function updateSavedSearch(config) {

  const {
    pathParams: {
      id
    },
    params: {
      organizationId
    },
    data: {
      dateRangeType,
      description,
      filter,
      name
    }
  } = config;
  const url = `${apiBase}/communications/saved-searches/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    params: {
      organizationId
    },
    data: {
      dateRangeType,
      description,
      filter,
      name
    }
  })
}

/**
 * Delete a saved search
 *
 * @param {Object} config
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.id The saved search id
 * @param {Object} config.params  Querystring parameters
 * @param {string<uuid>} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 *
 * @returns {Promise}
 * */
function deleteSavedSearch(config) {

  const {
    pathParams: {id},
    params: {organizationId}
  } = config;

  const url = `${apiBase}/communications/saved-searches/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE',
    params: {
      organizationId
    }
  });
}

/**
 * Returns a paginated list of saved searches
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {number=} [config.params.page=0] Zero-based page index (0..N)
 * @param {number=} [config.params.size=100] The size of the page to be returned
 * @param {string=} config.params.sort Sorting criteria in the format: field(,asc|desc).
 * @param {Object} config.data Body of request
 * @param {string=} config.data.quickSearchText Quick Search for saved-searches based on their name and description, if contains any word or phrase it will be returned and is case insensitive
 * @param {string[]} config.data.savedSearchIds ["00000000-0000-0000-0000-000000000000"] Filter saved-searches with these ids.
 * @param {AbortSignal} config.signal use to cancel request
 *
 * @returns {Promise}
 * */
function getSavedSearches(config) {

  const {
    params: {
      organizationId,
      page,
      size,
      sort
    },
    data,
    signal
  } = config

  const url = `${apiBase}/communications/saved-searches/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    params: {
      organizationId,
      page,
      size,
      sort
    },
    data,
    signal
  });
}

/**
 * Gets a schedule to a saved search by id. Can only modify schedules for your own saved-searches
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.searchId The saved search id
 * @param {string} config.pathParams.id The schedule id
 * @param {AbortSignal} config.signal use to cancel request
 *
 * @returns {Promise}
 * */
function getSavedSearchSchedule(config) {

  const {
    pathParams: {
      searchId,
      id
    },
    params: {
      organizationId
    },
    signal
  } = config;

  const url = `${apiBase}/communications/saved-searches/${searchId}/schedules/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',    params: {
      organizationId
    },
    signal
  });
}

/**
 *
 * Adds a schedule to a saved search by id. Can only modify schedules for your own saved-searches. Only one schedule allowed per search.
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.searchId The saved search id
 * @param {Object} config.data request body
 * @param {string} config.data.contentType One of [COMMUNICATIONS_SIMPLE, COMMUNICATIONS_DETAILED]
 * @param {string} config.data.exportDeliveryMode One of [LINK, XLSX]
 * @param {Object} config.data.schedule
 * @param {number[]=} config.data.schedule.daysOfMonth Integer(s) from 1 to 31. Specific days of the month that the report should be sent on. It is acceptable to provide more than one. It is not recommend to use values >28 as it will result in months without a delivery when the month does not contain the particular date. Required when frequencyType is MONTHLY. Otherwise should not be set.
 * @param {string[]=} config.data.schedule.daysOfWeek One or more of [ MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY ]. Days of the week the report should be sent. Required when frequencyType is WEEKLY. Otherwise should not be set.
 * @param {string=} config.data.schedule.endDate date/time string based on ISO 8601. Timestamp of when the schedule ends. No reports will be sent after this time. The schedule will automatically be deleted by the system some time after this time. If null, the schedule never ends and will continue to send reports until it is deleted or updated.
 * @param {string} config.data.schedule.frequencyType One of [ DAILY, WEEKLY, MONTHLY ]. Frequency of delivery of the schedule. The frequencyType controls which other schedule fields are required.
 * @param {number=} config.data.schedule.repeatDailyInterval Integer from 1 to 365. Number of days in between deliveries of reports. Required when frequencyType is DAILY. Otherwise should not be set.
 * @param {number=} config.data.schedule.repeatMonthlyInterval Integer from 1 to 12. Number of months in between deliveries of reports. Required when frequencyType is MONTHLY. Otherwise should not be set. The schedule startDate will be used as the first month to deliver in and the other months will be calculated based on the interval. The reports will be delivered the same months every year.
 * @param {string} config.data.schedule.runAtTime use format HH:MM:SS example: 02:30:00 Time which the report should be ran on each scheduled day.
 * @param {string} config.data.schedule.startDate date/time string based on ISO 8601. Timestamp when the schedule should start. No reports will be sent before the time.
 *
 * @returns {Promise}
 * */
function createSavedSearchSchedule(config) {
  const {
    pathParams: {searchId},
    params,
    data
  } = config;

  const url = `${apiBase}/communications/saved-searches/${searchId}/schedules`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    params,
    data
  });
}

/**
 *
 * Updates a schedule to a saved search by id. Can only modify schedules for your own saved-searches
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.searchId The saved search id
 * @param {string} config.pathParams.scheduleId The schedule id
 * @param {Object} config.data request body
 * @param {string} config.data.contentType One of [COMMUNICATIONS_SIMPLE, COMMUNICATIONS_DETAILED]
 * @param {string} config.data.exportDeliveryMode One of [LINK, XLSX]
 * @param {Object} config.data.schedule
 * @param {number[]=} config.data.schedule.daysOfMonth Integer(s) from 1 to 31. Specific days of the month that the report should be sent on. It is acceptable to provide more than one. It is not recommend to use values >28 as it will result in months without a delivery when the month does not contain the particular date. Required when frequencyType is MONTHLY. Otherwise should not be set.
 * @param {string[]=} config.data.schedule.daysOfWeek One or more of [ MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY ]. Days of the week the report should be sent. Required when frequencyType is WEEKLY. Otherwise should not be set.
 * @param {string=} config.data.schedule.endDate date/time string based on ISO 8601. Timestamp of when the schedule ends. No reports will be sent after this time. The schedule will automatically be deleted by the system some time after this time. If null, the schedule never ends and will continue to send reports until it is deleted or updated.
 * @param {string} config.data.schedule.frequencyType One of [ DAILY, WEEKLY, MONTHLY ]. Frequency of delivery of the schedule. The frequencyType controls which other schedule fields are required.
 * @param {number=} config.data.schedule.repeatDailyInterval Integer from 1 to 365. Number of days in between deliveries of reports. Required when frequencyType is DAILY. Otherwise should not be set.
 * @param {number=} config.data.schedule.repeatMonthlyInterval Integer from 1 to 12. Number of months in between deliveries of reports. Required when frequencyType is MONTHLY. Otherwise should not be set. The schedule startDate will be used as the first month to deliver in and the other months will be calculated based on the interval. The reports will be delivered the same months every year.
 * @param {string} config.data.schedule.runAtTime use format HH:MM:SS example: 02:30:00 Time which the report should be ran on each scheduled day.
 * @param {string} config.data.schedule.startDate date/time string based on ISO 8601. Timestamp when the schedule should start. No reports will be sent before the time.
 *
 * @returns {Promise}
 * */
function updateSavedSearchSchedule(config) {
  const {
    pathParams: {
      searchId,
      scheduleId
    },
    params,
    data
  } = config;

  const url = `${apiBase}/communications/saved-searches/${searchId}/schedules/${scheduleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    params,
    data
  })
}

/**
 *
 * Deletes a schedule to a saved search by id. Can only modify schedules for your own saved-searches
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.searchId The saved search id
 * @param {string} config.pathParams.scheduleId The id of the schedule to be deleted
 *
 * @returns {Promise}
 * */
function deleteSavedSearchSchedule(config) {
  const {
    params,
    pathParams: {
      searchId,
      scheduleId
    }
  } = config;

  const url = `${apiBase}/communications/saved-searches/${searchId}/schedules/${scheduleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE',
    params,
  });
}

/**
 * Initiates an export and returns the ID and status. Export results are limited to 10k rows.
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string} [config.params.type=xlsx] The report output format.
 * @param {string} [config.params.contentType=COMMUNICATIONS_SIMPLE] One of: [COMMUNICATIONS_SIMPLE, COMMUNICATIONS_DETAILED]. The report content type.
 * @param {string<uuid>} config.params.organizationId The organization id of the user making the request. This is used for permission enforcement and templating, NOT for filtering.
 * @param {string} [config.params.locale = 'en'] The requested language to return the airport names in ISO 639-1 format.
 * @param {boolean} [config.params.processSynchronously=false] Makes the report process synchronously, mainly used for debugging
 * @param {string} [config.params.sort= 'sentDate,DESC'] Allowed sort fields are sentDate,direction ASC|DESC.
 *
 * @param {CommunicationSearchFilter} config.data The filtering params.
 *
 * @returns {Promise}
 * */
function initiateCommHistoryExport (config) {

  const {
    params,
    data
  } = config;

  const url = `${apiBase}/communications/exports`;

  return waRequest.requestAuthenticated({
    url,
    method: 'POST',
    params,
    data
  })
}

/**
 * Returns the export ID and status for the provided export ID.
 *
 * @param {Object} config
 * @param {Object} config.params Querystring parameters
 * @param {string<uuid>} config.params.id An export identifier.
 * @param {Function} config.successCallback The callback function to call upon a successful completion of request
 *
 * @returns {Promise}
 * */
function getCommHistoryExportStatus(config) {
  const {
    pathParams: {
      id
    },
    successCallback
  } = config;

  const url = `${apiBase}/exports/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url,
    method: 'get'
  },
  successCallback
  );

}

export default {
  getListOfCommunications,
  getListOfCommunicationsWrapper,
  getCommunication,
  getReportOfCommunication,
  getRecipientsOfCommunication,
  getCommunicationsAlertHistoryRecipients,

  getListOfMobileCheckins,
  getReportOfListOfMobileCheckins,
  getMobileCheckin,
  getReportOfMobileCheckin,
  getRecipientsOfMobileCheckin,

  getRollupSearches,
  getReportOfRollupSearches,
  getRollupMessageDetails,
  getRollupMessageRecipients,
  getRollupMessageReport,
  getCommunicationBody,
  getCommunicationConfigPattern,

  getCommunicationSubTypes,
  createSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
  getSavedSearches,
  getSavedSearchSchedule,
  createSavedSearchSchedule,
  updateSavedSearchSchedule,
  deleteSavedSearchSchedule,
  initiateCommHistoryExport,
  getCommHistoryExportStatus
};
