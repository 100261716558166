import waHttp from './wa-http';
import waRequest from './wa-request';

export const apiBase = '/self-registration/v1';
const baseURL = process.env.REACT_APP_GATEWAY_URL;

/**
 * Initiate UNauthenticated POST ${apiBase}/sign-up
 *
 * @returns {Promise}
 */
function signUp(signUpRequest) {
  const url = `${apiBase}/sign-up`;

  return waHttp.httpRequest({
    baseURL: baseURL,
    url: url,
    method: 'post',
    data: signUpRequest
  });
}

/**
 * @typedef {{organization: object, emailDomains: string[], roles: object[], type: string}} SelfRegPolicy
 */

/**
 * @param orgId
 * @returns {Promise<SelfRegPolicy[]>}
 */
function getSelfRegPolicies(orgId) {
  const url = `${apiBase}/organizations/${orgId}/self-reg-policies`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function deleteSelfRegPolicy(orgId, policyId) {
  const url = `${apiBase}/organizations/${orgId}/self-reg-policies/${policyId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 *
 * @param selfRegPolicyId
 * @param {SelfRegPolicy} updatedPolicy
 * @returns {Promise}
 */
function updateSelfRegPolicy(selfRegPolicyId, updatedPolicy) {
  const url = `${apiBase}/self-reg-policies/${selfRegPolicyId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: updatedPolicy
  });
}

/**
 *
 * @param orgId
 * @param {SelfRegPolicy[]} newSelfRegPolicy
 * @returns {Promise}
 */
function createSelfRegPolicy(orgId, newSelfRegPolicy) {
  const url = `${apiBase}/organizations/${orgId}/self-reg-policies`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: newSelfRegPolicy
  });
}

/**
 *
 * @param {("VALID_EMAIL_DOMAIN"|"FSSO"|"MEMBER_ID")} type
 * @param emailDomain
 * @param memberId
 * @param clientUrlString
 * @returns {Promise<*>}
 */
function selfRegPoliciesExists({type, emailDomain, memberId, clientUrlString}) {
  const url = `${apiBase}/self-reg-policies-exists`;

  return waHttp.httpRequest({
    baseURL: baseURL,
    url: url,
    method: 'get',
    params: {
      emailDomain,
      memberId,
      type,
      clientUrlString
    }
  });
}

/**
 * Initiate UNauthenticated GET ${apiBase}/self-reg-policies-exists
 *
 * @param type
 * @param emailDomain
 * @param providerName
 * @param memberId
 * @param clientUrlString
 * @param fssoSubType
 * @returns {Promise<*>}
 */
function getSelfRegistrationPoliciesExists({params: {type, emailDomain, providerName, memberId, clientUrlString, fssoSubType}}) {
  const url = `${apiBase}/self-reg-policies-exists`;

  return waHttp.httpRequest({
    baseURL: baseURL,
    url: url,
    method: 'get',
    params: {
      emailDomain,
      providerName,
      memberId,
      type,
      clientUrlString,
      fssoSubType
    }
  });
}

/**
 *
 * @param type
 * @param memberId
 * @param emailDomain
 * @param providerName
 * @returns {Promise}
 */
function getSelfRegPoliciesByType({type, memberId, providerName, emailDomain}) {
  const url = `${apiBase}/self-reg-policies`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      memberId,
      emailDomain,
      providerName,
      type
    }
  });
}

function getSignUpLink({pathParams: {linkId}}) {
  const url = `${apiBase}/sign-up-links/${linkId}`;

  return waHttp.httpRequest({
    baseURL: baseURL,
    url: url,
    method: 'get'
  });
}

/**
 * Get customized configuration for the member ID
 *
 * @param {Object} config
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.orgId the organization id of the customized configuration
 * @param {Object} config.params
 * @param {string} config.params.locale the locale of the desired translation
 *
 * @returns{Promise}
 * */
function getMemberIdConfiguration(config) {

  const {
    pathParams: {orgId},
    params: {locale}
  } = config;

  const url = `${apiBase}/organizations/${orgId}/self-reg-policies/configurations/member-id`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

/**
 * Update customized member ID configuration for the organization
 *
 * */
function updateMemberIdConfiguration(config) {

  const {
    pathParams: {orgId},
    data: {
      organizationId,
      label,
      description
    }

  } = config;

  const url = `${apiBase}/organizations/${orgId}/self-reg-policies/configurations/member-id`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {
      organizationId,
      label,
      description
    }
  });
}


/**
 * Delete customized member ID configuration for the organization
 *
 * */
function deleteMemberIdConfiguration(config) {

  const {
    pathParams: {orgId}
  } = config

  const url = `${apiBase}/organizations/${orgId}/self-reg-policies/configurations/member-id`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 * Get self registration policies summary the supplied client url string and typ
 *
 * @param {Object} config
 * @param {Object} config.params
 * @param {string} config.params.locale the locale of the desired translation
 * @param {string} config.params.clientUrlString A unique client identifier to search for policies by
 * @param {} config.params.type one of VALID_EMAIL_DOMAIN, MEMBER_ID, or FSSO
 *
 *
 * @returns{Promise}
 * */
function getSelfRegistrationPoliciesSummary(config) {

  const {
    params: {
      locale,
      clientUrlString,
      type
    }
  } = config;

  const url = `${apiBase}/self-reg-policies/summary`;

  return waHttp.httpRequest({
    baseURL: baseURL,
    url: url,
    method: 'get',
    params: {
      locale,
      clientUrlString,
      type
    }
  });
}

function getMFAConfiguration(config) {

  const {
    pathParams: {orgId}
  } = config;

  const url = `${apiBase}/organizations/${orgId}/mfa-config`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function updateMFAConfiguration(config) {

  const {
    pathParams: {orgId},
    data: {
      requiredMFARoleDefinitionIds
    }
  } = config;

  const url = `${apiBase}/organizations/${orgId}/mfa-config`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: {
      requiredMFARoleDefinitionIds
    }
  });
}

export default {
  signUp,
  getSelfRegPolicies,
  deleteSelfRegPolicy,
  updateSelfRegPolicy,
  createSelfRegPolicy,
  selfRegPoliciesExists,
  getSelfRegPoliciesByType,
  getSelfRegistrationPoliciesExists,
  getSignUpLink,
  getMemberIdConfiguration,
  updateMemberIdConfiguration,
  deleteMemberIdConfiguration,
  getSelfRegistrationPoliciesSummary,
  getMFAConfiguration,
  updateMFAConfiguration
};

