import waRequest from './wa-request';

const cancelableBulkUploadListSingleton = waRequest.createCancelableSingleton();

export const baseApi = '/persons/v1';

/**
 * Initiate authenticated POST ${baseApi}/person to create a new person
 *
 * @param person
 * @returns {Promise}
 */
function createPerson(person) {
  const url = `${baseApi}/persons`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: person
  });
}

/**
 * Initiate authenticated PUT `${baseApi}/persons/${personId}`
 *
 * @param {string} personId
 * @param {object} payload data to be updated
 * @returns {Promise}
 */
function updatePerson(personId, payload) {
  const url = `${baseApi}/persons/${personId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data: payload
  });
}

/**
 * Initiate authenticated DELETE `${baseApi}/persons/${personId}`
 *
 * @param {string} personId
 * @returns {Promise}
 */
function deletePerson(personId) {
  const url = `${baseApi}/persons/${personId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

/**
 * Initiate authenticated POST ${baseApi}/persons/${personId}/restore
 *
 * @param personId
 * @returns {Promise}
 */
function restorePerson(personId) {
  const url = `${baseApi}/persons/${personId}/restore`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

/**
 * Initiate authenticated POST ${baseApi}/persons/${personId}/purge
 *
 * @param personId
 * @returns {Promise}
 */
function purgePerson(personId) {
  const url = `${baseApi}/persons/${personId}/purge`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

/**
 * Initiate authenticated GET ${baseApi}/person
 *
 * @param page
 * @param size
 * @param sort
 * @param filterParams
 * @param organizationId
 * @returns {Promise}
 */
function getPersonsList(page, size, sort, filterParams, organizationId) {
  const url = `${baseApi}/persons`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      page,
      size,
      sort,
      organizationId,
      ...filterParams
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/labels
 */
function getLabels(locale) {
  const url = `${baseApi}/labels`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {locale}
  });
}

/**
 * Initiate authenticated GET ${baseApi}/${personId}/onboarding
 */
function getOnboardingInfo(personId) {
  const url = `${baseApi}/persons/${personId}/onboarding`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

/**
 * Initiate authenticated post ${baseApi}/${personId}/onboarding
 */
function createOnboardingInfo(personId, onBoardingData) {
  const url = `${baseApi}/persons/${personId}/onboarding`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: onBoardingData
  });
}

/**
 * Initiate authenticated GET ${baseApi}/types to get person type
 *
 * @returns {Promise}
 */
function getPersonTypes(locale) {
  const url = `${baseApi}/types`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {locale}
  });
}

/**
 * Initiate authenticated GET ${baseApi}/bulkUploads/example to get bulk upload example template file
 *
 * @returns {Promise}
 */
function getBulkUploadExample(values) {
  const url = `${baseApi}/bulkUploads/example`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: values,
    responseType: 'blob'
  });
}

/**
 * Initiate authenticated POST Bulk Upload file to ${baseApi}/bulkUploads
 *
 * @param {FormData} data File converted to FormData
 * @returns {Promise}
 */
function uploadFile(data) {
  const url = `${baseApi}/bulkUploads`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  });

}

/**
 * Initiate authenticated GET Bulk Upload errors for file to ${baseApi}/bulkUploads/{id}/errors
 *
 * @param {string} id The bulk upload id
 * @returns {Promise}
 */
function getFileErrors({pathParams: {fileId}}) {
  const url = `${baseApi}/bulkUploads/${fileId}/errors`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });

}

/**
 * Initiate authenticated GET Bulk Upload file details to ${baseApi}/bulkUploads/{id}
 *
 * @param {string} id The bulk upload id
 * @param successCallback
 * @returns {Promise}
 */
function getFileDetails(id, successCallback) {
  const url = `${baseApi}/bulkUploads/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback,
  5000
  );

}

/**
 * Initiate authenticated GET ${baseApi}/bulkUploads to get bulk uploads list
 *
 * @param {{organizationId: string, page: number, size: number, sort:string}} params
 * @returns {Promise}
 */
function getBulkUploadsListDeprecated(params) {
  const url = `${baseApi}/bulkUploads`;

  cancelableBulkUploadListSingleton.cancel();

  return cancelableBulkUploadListSingleton.requestAuthenticated({
    url: url,
    method: 'get',
    params
  });
}

function getBulkUploadsList({params: {size = 25, page = 0, organizationId, sort = 'createdAt,desc'}, signal}) {
  const url = `${baseApi}/bulkUploads`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    signal,
    params: {
      size,
      page,
      organizationId,
      sort
    }
  });
}

/**
 * Initiate authenticated GET ${baseApi}/persons/${personId}, retries until callback is true
 * returns with userId data.
 *
 * @param configData
 * @param configData.pathParams.personId - the person's id
 * @param configData.params - defaults locale to 'en' if not supplied
 * @param configData.successCallback - if exists then this uses requestWithRetryUntilSuccess and passes this to it
 * @returns {Promise} - returns promise from requestAuthenticated unless there is a successCallback supplied
 */
function getPersonConfigPattern(configData) {

  const {
    pathParams: {personId},
    params: {locale = 'en', ...rest} = {},
    successCallback
  } = configData;

  const url = `${baseApi}/persons/${personId}`;

  if(successCallback) {
    return waRequest.requestWithRetryUntilSuccess({
      url: url,
      method: 'get',
      params: {locale, ...rest}
    },
    successCallback
    );
  }

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {locale, ...rest}
  });
}

function getDataFeeds({params: {organizationId}, signal}){
  const url = `${baseApi}/personDataFeeds`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {organizationId},
    signal
  });
}

/**
 * Create a person data feed config
 *
 * @param {Object} config
 * @param {Object} config.data
 * @param {string} config.data.name Display name for the feed
 * @param {boolean} config.data.enabled If true, enables the feed to process files
 * @param {string} config.data.clientId uuid - Internal id for the client organization
 * @param {string} config.data.newPersonOrganizationId uuid - Internal id for the organization for which new persons will be created. Must be in the same hierachy as the client organization.
 * @param {string} config.data.updateAction One of [ ADD, OVERWRITE ], Determines how data for matching persons will be handled
 * @param {string} config.data.fileType One of [ XLSX, TEXT ], The type of files that will be processed
 * @param {Object} config.data.connection
 * @param {Object} config.data.notification
 * @param {boolean} config.data.mappingEnabled If true, enables the mapping for a feed
 * @param {boolean} config.data.sitesFieldsEnabled If true, enables the mapping for bulk uploading sites through person data feed
 * @param {Object} config.data.mapping
 * @param {string} config.data.mapping.sitesUpdateAction Type of action for site fields information. Can be ADD or OVERWRITE
 * @param {Object} config.data.mapping.fileFormat
 * @param {string} config.data.mapping.fileFormat.mappingBy One of  [ COLUMN_NUMBER, COLUMN_HEADER ]
 * @param {number} config.data.mapping.linesToSkip Number of lines to skip
 * @param {string} config.data.mapping.delimiter One of [ CARET, COMMA, PIPE, SEMICOLON, TAB ]
 * @param {string} config.data.mapping.quote One of [ DOUBLE_QUOTE, SINGLE_QUOTE, TICK_MARK ], Character to separate values
 * @param {string} config.data.mapping.endOfFile Text to signal that is file is done. Usually EOF
 * @param {Array.<string>} config.data.mapping.fields List of fields. Following fields are required: Identifier(1), Name(1), Email(1), Primary Organization Identifier, and Status
 * @param {Array.<string>} config.data.mapping.siteFields Includes information for site fields such Site Name, Site Type, Site Organization, Site Identifier, Site Address Fields
 * */
function createDataFeed(config){

  const   {
    data
  } = config;

  const url = `${baseApi}/personDataFeeds`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    data
  });
}

function generateSshKeyPair(){
  const url = `${baseApi}/personDataFeeds/generateSshKeyPair`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

function getDataFeed({pathParams: {id}, signal}){
  const url = `${baseApi}/personDataFeeds/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    signal
  });
}

/**
 * Update a person data feed config
 *
 * @param {Object} config
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.id uuid - The person data feed config ID.
 * @param {Object} config.data
 * @param {string} config.data.name Display name for the feed
 * @param {boolean} config.data.enabled If true, enables the feed to process files
 * @param {string} config.data.clientId uuid - Internal id for the client organization
 * @param {string} config.data.newPersonOrganizationId uuid - Internal id for the organization for which new persons will be created. Must be in the same hierachy as the client organization.
 * @param {string} config.data.updateAction One of [ ADD, OVERWRITE ], Determines how data for matching persons will be handled
 * @param {string} config.data.fileType One of [ XLSX, TEXT ], The type of files that will be processed
 * @param {Object} config.data.connection
 * @param {Object} config.data.notification
 * @param {boolean} config.data.mappingEnabled If true, enables the mapping for a feed
 * @param {boolean} config.data.sitesFieldsEnabled If true, enables the mapping for bulk uploading sites through person data feed
 * @param {Object} config.data.mapping
 * @param {string} config.data.mapping.sitesUpdateAction Type of action for site fields information. Can be ADD or OVERWRITE
 * @param {Object} config.data.mapping.fileFormat
 * @param {string} config.data.mapping.fileFormat.mappingBy One of  [ COLUMN_NUMBER, COLUMN_HEADER ]
 * @param {number} config.data.mapping.linesToSkip Number of lines to skip
 * @param {string} config.data.mapping.delimiter One of [ CARET, COMMA, PIPE, SEMICOLON, TAB ]
 * @param {string} config.data.mapping.quote One of [ DOUBLE_QUOTE, SINGLE_QUOTE, TICK_MARK ], Character to separate values
 * @param {string} config.data.mapping.endOfFile Text to signal that is file is done. Usually EOF
 * @param {Array.<string>} config.data.mapping.fields List of fields. Following fields are required: Identifier(1), Name(1), Email(1), Primary Organization Identifier, and Status
 * @param {Array.<string>} config.data.mapping.siteFields Includes information for site fields such Site Name, Site Type, Site Organization, Site Identifier, Site Address Fields
 * */
function updateDataFeed(config){

  const   {
    pathParams: {id},
    data
  } = config;

  const url = `${baseApi}/personDataFeeds/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    data
  });
}

function deleteDataFeed({pathParams: {id}}){
  const url = `${baseApi}/personDataFeeds/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE'
  });
}

function testConnectionDataFeed({pathParams: {id}}){
  const url = `${baseApi}/personDataFeeds/${id}/testConnection`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST'
  });
}

function executeMergePersons({pathParams: {id}}){
  const url = `${baseApi}/personMerges/${id}/merges`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST'
  });
}

function mergePersons({data: {survivingPersonId, mergingPersonIds}, params: {locale = 'en'}}){
  const url = `${baseApi}/personMerges`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    data: {
      survivingPersonId,
      mergingPersonIds
    },
    params: {locale}
  });
}

function getPersonMergeById({pathParams: {id}, params: {locale = 'en'}}){
  const url = `${baseApi}/personMerges/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    params: {locale}
  });
}

function getPersonMergeByIdWithCallback({pathParams: {id}, params: {locale = 'en'}, successCallback}){
  const url = `${baseApi}/personMerges/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get',
    params: {locale}
  },
  successCallback
  );
}

function getPersonDataFeedsInfo(){
  const url = `${baseApi}/personDataFeeds/info`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET'
  });
}

function generatePgpKeyPair(){
  const url = `${baseApi}/personDataFeeds/generatePgpKeyPair`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

function getPersonDataFeedUploads(configData, signal) {
  const {
    pathParams: {dataFeedId},
    params,
  } = configData;

  const url = `${baseApi}/bulkUploads/personDataFeed/${dataFeedId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    params,
    signal
  });
}

/**
 * Initiate authenticated POST Bulk Upload file to ${baseApi}/bulkUploads
 *
 * @param {file: File, isTest: boolean} object with binary file and isTest flag
 * @returns {Promise}
 */
function uploadPersonDataFeed({data, pathParams: {personDataFeedId}}) {
  const url = `${baseApi}/personDataFeeds/${personDataFeedId}/uploads`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  });
}

/**
 * Initiate authenticated GET Data Feed Bulk Upload raw file to ${baseApi}/bulkUploads/{id}/raw
 *
 * @param {string} id The bulk upload id
 * @returns {Promise}
 */
function getDataFeedUploadRawFile({pathParams: {fileId}}) {
  const url = `${baseApi}/bulkUploads/${fileId}/raw`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });

}

/**
 * Initiate authenticated GET Data Feed Bulk Upload mapped data file to ${baseApi}/bulkUploads/{id}/raw
 *
 * @param {string} id The bulk upload id
 * @returns {Promise}
 */
function getDataFeedUploadMappedFile({pathParams: {fileId}}) {
  const url = `${baseApi}/bulkUploads/${fileId}/mapped`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });

}

/**
 * Initiate authenticated GET Person Matching Patterns ${baseApi}/personMatching/patterns
 *
 * @param {string} id The bulk upload id
 * @returns {Promise}
 */
function getPersonMatchingPatterns({params: {locale}}) {
  const url = `${baseApi}/personMatching/patterns`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });

}

/**
 * Initiate authenticated GET Person Matching Patterns ${baseApi}/personMatching/{clientId}/configs
 *
 * @param {string} id The bulk upload id
 * @returns {Promise}
 */
function getPersonMatchingConfiguration({pathParams: {clientId}, params: {locale}}) {
  const url = `${baseApi}/personMatching/${clientId}/configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

/**
 * Initiate authenticated PUT Person Matching Patterns ${baseApi}/personMatching/{clientId}/configs
 *
 * @param clientId
 * @param data
 * @returns {Promise}
 */
function setPersonMatchingConfiguration({pathParams: {clientId}, data}) {
  const url = `${baseApi}/personMatching/${clientId}/configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data
  });
}

function getFilesPersonDataFeed({
  pathParams: {id}
}) {
  const url = `${baseApi}/personDataFeeds/${id}/serverFiles`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getDataFeedFileOnServerUploadRawFile({pathParams: {id, fileName}}) {
  const url = `${baseApi}/personDataFeeds/${id}/serverFiles/${fileName}/raw`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });

}

function getDataFeedFileOnServerUploadMappedFile({pathParams: {id, fileName}}) {
  const url = `${baseApi}/personDataFeeds/${id}/serverFiles/${fileName}/mapped`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });
}

function getBulkUploadSummaryPdf({pathParams: {id}, params}) {
  const url = `${baseApi}/bulkUploads/${id}/summary`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

function deleteDataFeedFileOnServerFile({pathParams: {id, fileName}}){
  const url = `${baseApi}/personDataFeeds/${id}/serverFiles/${fileName}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE'
  });
}

function getCustomFieldConfigs({params, signal}){
  const url = `${baseApi}/customFieldConfigs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    signal,
    params
  });
}

function createCustomFieldConfigs(
  {
    data: {
      organizationId,
      fields
    },
    params: {
      locale = 'en'
    }
  }){
  const url = `${baseApi}/customFieldConfigs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    data: {
      organizationId,
      fields
    },
    params: {
      locale
    }
  });
}

function updateCustomFieldConfigs(
  {
    pathParams: {id},
    data: {
      organizationId,
      fields
    },
    params: {
      locale = 'en'
    }
  }){
  const url = `${baseApi}/customFieldConfigs/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    data: {
      organizationId,
      fields
    },
    params: {
      locale
    }
  });
}

function deleteCustomFieldConfigs({pathParams: {id}}){
  const url = `${baseApi}/customFieldConfigs/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE'
  });
}

function getLockedFields({params, signal}){
  const url = `${baseApi}/organizationLockedFields`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    signal,
    params
  });
}

export default {
  getPersonConfigPattern,
  createPerson,
  updatePerson,
  deletePerson,
  purgePerson,
  restorePerson,
  getPersonsList,
  uploadFile,
  getFileDetails,
  getFileErrors,
  getLabels,
  getOnboardingInfo,
  createOnboardingInfo,
  getPersonTypes,
  getBulkUploadExample,
  getBulkUploadsListDeprecated,
  getBulkUploadsList,
  createDataFeed,
  updateDataFeed,
  deleteDataFeed,
  getDataFeed,
  getDataFeeds,
  getFilesPersonDataFeed,
  generateSshKeyPair,
  testConnectionDataFeed,
  mergePersons,
  getPersonMergeById,
  executeMergePersons,
  getPersonMergeByIdWithCallback,
  getPersonDataFeedsInfo,
  generatePgpKeyPair,
  getPersonDataFeedUploads,
  uploadPersonDataFeed,
  getDataFeedUploadRawFile,
  getDataFeedUploadMappedFile,
  getPersonMatchingPatterns,
  getPersonMatchingConfiguration,
  setPersonMatchingConfiguration,
  getDataFeedFileOnServerUploadRawFile,
  getDataFeedFileOnServerUploadMappedFile,
  deleteDataFeedFileOnServerFile,
  getCustomFieldConfigs,
  createCustomFieldConfigs,
  updateCustomFieldConfigs,
  deleteCustomFieldConfigs,
  getBulkUploadSummaryPdf,
  getLockedFields
};
