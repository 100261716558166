import waRequest from './wa-request';

export const baseApi = '/templates/v1';

function getCustomContentConfig(organizationId, templateType) {
  const url = `${baseApi}/organizations/${organizationId}/templates/custom-content-configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params:{
      variant: templateType
    }
  });
}

function addCustomContentConfig(organizationId, postData) {
  const url = `${baseApi}/organizations/${organizationId}/templates/custom-content-configs`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      variant: postData.templateType,
      language: postData.language,
      content: postData.content,
      type: postData.type
    }
  });
}

function updateCustomContentConfig(organizationId, customContentConfigId, content) {
  const url = `${baseApi}/organizations/${organizationId}/templates/custom-content-configs/${customContentConfigId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data: {
      content
    }
  });
}

function deleteCustomContentConfig(organizationId, customContentConfigId, content) {
  const url = `${baseApi}/organizations/${organizationId}/templates/custom-content-configs/${customContentConfigId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function createCustomTemplateConfig({pathParams:{organizationId}, data}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  });
}

function updateCustomTemplateConfig({pathParams:{organizationId, customTemplateId}, data}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/${customTemplateId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'patch',
    data
  });
}

function deleteCustomTemplateConfig({pathParams:{organizationId, customTemplateId}}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/${customTemplateId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function deleteCustomTemplateTypeConfig({pathParams:{organizationId, customTemplateType}}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/types/${customTemplateType}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'delete'
  });
}

function getCustomTemplateById({pathParams:{organizationId, customTemplateId}}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/${customTemplateId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getCustomTemplateByTypeAndLanguage({pathParams:{organizationId, customTemplateType, language}}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/types/${customTemplateType}/languages/${language}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getAvailableCustomTemplateLanguages({pathParams:{organizationId, customTemplateType}}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/types/${customTemplateType}/languages`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function duplicateTemplateType({pathParams:{organizationId, customTemplateType}, data: {language}}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/types/${customTemplateType}/duplicate`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {language}
  })
}

function previewTemplate({pathParams:{organizationId}, data}) {
  const url = `${baseApi}/organizations/${organizationId}/custom-templates/preview`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  })
}

function renderTemplate({data: {filter}}) {
  const url = `${baseApi}/templates/render`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: {
      filter
    }
  })
}

function uploadImage({pathParams: {organizationId}, data: {file}}) {
  const url = `${baseApi}/organizations/${organizationId}/images`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data: file,
  });
}

function getImageById({pathParams:{organizationId, imageId}}) {
  const url = `${baseApi}/organizations/${organizationId}/images/${imageId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}


export default {
  getCustomContentConfig,
  addCustomContentConfig,
  updateCustomContentConfig,
  deleteCustomContentConfig,
  createCustomTemplateConfig,
  updateCustomTemplateConfig,
  deleteCustomTemplateConfig,
  getCustomTemplateById,
  getCustomTemplateByTypeAndLanguage,
  getAvailableCustomTemplateLanguages,
  duplicateTemplateType,
  previewTemplate,
  renderTemplate,
  deleteCustomTemplateTypeConfig,
  uploadImage,
  getImageById
};
