import _ from 'lodash';
import {NOTIFICATION_TYPES} from '../redux/modules/preferences/utils';
import {formatLocations} from './preferences';
import {generateEditModeReportOptions} from '../containers/Preferences/_shared/utils.report-form';
import {processForApi} from '../redux/modules/preferences/utils.report-form';

function transformTripBookingToService(preferences) {
  const report = _.get(preferences, ['report']);

  const destinationTypes = _.get(preferences, ['destinationTypes']);
  const alwaysSendForHotelOnlyTrips = _.get(preferences, ['alwaysSendForHotelOnlyTrips']);

  return {
    name: preferences.name,
    type: NOTIFICATION_TYPES.TRIP_BOOKINGS,
    config: {
      locationIds: preferences.locations?.map(location => location.value),
      personTypes: preferences.personTypes,
      minimumCountryRiskRating: preferences.minimumCountryRiskRating,
      notifyWhenTripIsUpdated: preferences.notifyWhenTripIsUpdated,
      notifyWhenTripIsCancelled: preferences.notifyWhenTripIsCancelled,
      report: preferences.includeReport ? processForApi(report) : undefined,
      destinationTypes: {alwaysSendForHotelOnlyTrips, ...destinationTypes}
    }
  };
}

function transformConfig(config = {}, locationsDict) {
  const {
    locationIds,
    report,
    destinationTypes = {},
    ...rest
  } = config;

  const {alwaysSendForHotelOnlyTrips, ...otherTypes} = destinationTypes;

  const hasDestinationType = Object.keys(otherTypes).reduce((accumulator, key) => accumulator || otherTypes[key] ,false)

  return {
    ...rest,
    locations: formatLocations(locationIds, locationsDict),
    report: generateEditModeReportOptions(report),
    includeReport: !!report,
    alwaysSendForHotelOnlyTrips,
    ...(hasDestinationType? {destinationTypes: otherTypes} : {}),

  }
}

function transformFromServiceToTripBooking(preference, locationsDict) {

  const {config, id, name, valid} = preference || {};

  return {
    ...transformConfig(config, locationsDict),
    id,
    name,
    valid
  };
}

export {
  transformTripBookingToService,
  transformFromServiceToTripBooking,
}