import {useCallback} from 'react';

import emailApi from '../../services/api/email';
import useBaseManageHook from '../../hooks/services/useBaseManageServiceHook';

const config = {
  createApi: emailApi.sendReadReceipt
};

export default function useReadReceipt() {
  const {create} = useBaseManageHook(config);

  const submitReadReceipt = useCallback((trackingId) => {
    const configData = {pathParams: {trackingId}};

    return create(configData);
  }, [create]);

  return {submitReadReceipt};
}