import {defineMessages, FormattedMessage} from 'react-intl';

const messages = defineMessages({
  domestic: {
    id: 'constants.preferences.trip-booking.domestic',
    defaultMessage: 'Domestic'
  },
  international: {
    id: 'constants.preferences.trip-booking.international',
    defaultMessage: 'International'
  },
  hotelOnly: {
    id: 'constants.preferences.trip-booking.hotelOnly',
    defaultMessage: 'For international only, always send for hotel-only trips'
  },
});

export const MODALITY_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS'
};

export const DEFAULT_SESSION_TIMEOUT_DURATION = 2;

export const NOTIFICATION_TYPES = {
  ALERT: 'ALERT_NOTIFICATION'
};

// per the doc:
// https://bitbucket.org/crisis24/wc4-api-docs/src/master/src/docs/private/preferences/wc4-preferences-ms.yaml
// we should be able to have a flat object with all the types. discuss with team
export const USER_PREF_TYPES = {
  SEARCH_FILTER: 'SEARCH_FILTER'
};

export const DESTINATION_TYPE_KEYS = {
  DOMESTIC: 'domestic',
  INTERNATIONAL: 'international',
  HOTEL_ONLY: 'alwaysSendForHotelOnlyTrips'
}

const {DOMESTIC, INTERNATIONAL, HOTEL_ONLY} = DESTINATION_TYPE_KEYS;

export const DESTINATION_TYPE_LABELS = {
  [DOMESTIC]: <FormattedMessage {...messages.domestic} />,
  [INTERNATIONAL]: <FormattedMessage {...messages.international} />,
  [HOTEL_ONLY]: <FormattedMessage {...messages.hotelOnly} />
}