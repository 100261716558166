import PropTypes from 'prop-types';

import {Skeleton, Space, Typography} from 'antd';

import fonts from '../../containers/App/fonts.module.css';

import InfoPopoverIcon from '../Common/InfoPopoverIcon/InfoPopoverIcon';

import './Titles.css';

const SIZES = {
  default: {
    title: fonts.SIZE_NORMAL,
    subtitle: fonts.SIZE_SMALL
  },
  small: {
    title: fonts.SIZE_SMALL,
    subtitle: fonts.SIZE_X_SMALL
  }
};

function Titles(props) {
  const {
    title,
    subtitle,
    info,
    isLoading,
    size = 'default'
  } = props;

  return (
    <Skeleton className={'Titles'} loading={isLoading} title={true} paragraph={false} active={true}>
      <Space>
        <Typography.Text strong={true} style={{fontSize: SIZES[size].title}}>
          {title}
        </Typography.Text>

        <span>
          {subtitle && (
            <Typography.Text type={'secondary'} style={{fontSize: SIZES[size].subtitle}}>
              {subtitle}
            </Typography.Text>
          )}

          {info && (
            <InfoPopoverIcon content={info}/>
          )}
        </span>

      </Space>
    </Skeleton>
  );
}

Titles.propTypes = {
  size: PropTypes.oneOf(['default', 'small']),
  title: PropTypes.node,
  isLoading: PropTypes.bool,
  subtitle: PropTypes.node,
  info: PropTypes.node
};

export default Titles;