import _ from 'lodash';

import {DEFAULT_PARENT_ID} from '../../../components/Organizations/constants';
import {getStorageCurrentPersona, setStorageCurrentPersona} from '../../../utils/persona-utils';
import {formUsername, setUsersPersonName} from '../../../utils/users/users';

export function parseUserResults(results, personaOverride) {
  const roleAssignments = _.get(results, 'data.roleAssignments', []);
  const permissionsMap = createPermissionMap(roleAssignments);
  const fullPermissions = buildFullPermissionsMap(permissionsMap);

  const userId = _.get(results, 'data.id');
  const personId = _.get(results, 'data.personId');
  const currentPermissions = computeCurrentPermissions(fullPermissions, userId, personaOverride);
  const aggregatedPermissionIds = _.get(results, 'data.aggregatedPermissionIds');
  const attributeRestrictions = _.get(results, 'data.attributeRestrictions');

  return {
    userDetails: setUsersPersonName(results.data),
    userId: userId,
    personId: personId,
    username: formUsername(results.data),
    roleAssignments: roleAssignments, // TODO we may not need roleAssignments now that we have permissions attribute?
    permissions: permissionsMap,
    fullPermissions: fullPermissions,
    currentPermissions,
    aggregatedPermissionIds,
    attributeRestrictions
  };
}

function computeCurrentPermissions(fullPermissions, userId, personaOverride) {

  const orgIds = Object.keys(fullPermissions);
  if (orgIds.length === 1) {
    return fullPermissions[orgIds[0]];
  }

  const storagePersona = getStorageCurrentPersona(userId);
  const currentPersonaId = personaOverride ?? storagePersona;
  if (personaOverride && personaOverride !== storagePersona) {
    setStorageCurrentPersona(personaOverride, userId);
  }

  const personaPermissions = fullPermissions[currentPersonaId];
  return personaPermissions ?? {};
}

// For each role assignment, create permissions object with
//    org id, org name and the enabled permissions.
export function createPermissionMap(roleAssignments) {
  const orgPermissionMapping = [];
  _.forEach(roleAssignments, role => {
    const {organizationRole: {organization: {id: currentOrgId, name: currentOrgName, parentId: currentParentId}, enabledPermissions}} = role;
    const currentOrgPermission = _.find(orgPermissionMapping, ['orgId', currentOrgId]);

    if (currentOrgPermission) {
      // Combine permissions when a user has multiple roles in the same organization
      currentOrgPermission.permissions = _.unionBy(currentOrgPermission.permissions, enabledPermissions, 'id');
    }
    else {
      orgPermissionMapping.push({
        orgId: currentOrgId,
        orgName: currentOrgName,
        parentId: currentParentId,
        permissions: enabledPermissions
      });
    }
  });
  return orgPermissionMapping;
}

// create a map of a users full permissions
export function buildFullPermissionsMap(permissionsMap) {
  const fullPermissionMap = {};
  permissionsMap.forEach(orgPermission => {
    const {orgId, orgName, parentId} = orgPermission;
    fullPermissionMap[orgId] = {
      orgName: orgName,
      orgId: orgId,
      parentId: parentId,
      isClient: parentId === DEFAULT_PARENT_ID,
      isRoot: orgId === DEFAULT_PARENT_ID,
      permissions: {}
    };
    orgPermission.permissions.forEach(permission => fullPermissionMap[orgId].permissions[permission.id] = true);
  });
  return fullPermissionMap;
}

export function getSessionOrgId(state) {
  return state?.session?.currentPermissions?.orgId;
}

function parseOrgHierarchyToOption(organization) {
  organization.label = organization?.name;
  organization.value = organization?.id;

  organization.children = _.map(organization?.children, parseOrgHierarchyToOption)
    .sort((organizationA,organizationB) => {
      const a = organizationA.name?.toUpperCase(); // ignore upper and lowercase
      const b = organizationB.name?.toUpperCase(); // ignore upper and lowercase
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }

      // names must be equal
      return 0;
    } );

  return organization;
}

export function parseOrganizationHierarchyResponse(userOrganizationResponse, fullPermissions) {
  const orgData = _.get(userOrganizationResponse, ['data', 'organizations'], {});
  const parsedOrgHierarchy = _.chain(orgData)
    .thru(data => [data])
    .map(parseOrgHierarchyToOption)
    .value();

  const organizationsList = reduceHierarchyToListApplyPermissions(parsedOrgHierarchy, {permissionsInherited: {}, fullPermissions});

  const organizationsDictionary = _.keyBy(organizationsList, 'id');

  return {
    parsedOrgHierarchy,
    organizationsDictionary,
    organizationsList
  }
}


/**
 * Processes through organization Hierarchy to apply permissions through the hierarchy objects and
 * flatten into a list. NOTE this applying of permission impact orgiional object but that is intended
 * so the original hierarchy data items get permission and other information
 *
 * @param hierarchy
 * @param permissionData
 * @param parentId
 * @param clientId
 * @param hierarchyPath
 * @returns {*}
 */
export function reduceHierarchyToListApplyPermissions(hierarchy, permissionData, parentId = null, clientId = null, hierarchyPath = []) {
  const {permissionsInherited, fullPermissions} = permissionData
  return hierarchy?.reduce((acc, organization) => {
    organization.parentId = parentId;
    organization.clientId = clientId ?? (parentId === DEFAULT_PARENT_ID ? organization.id : undefined);
    organization.hierarchyPath = [...hierarchyPath, {orgId: organization.id, orgName: organization.name}];

    const assignedPermissions = fullPermissions?.[organization.id]?.permissions
    organization.permissions = assignedPermissions ? {...permissionsInherited, ...assignedPermissions} :  permissionsInherited
    acc.push(organization);

    if (!_.isEmpty(organization.children)) {
      acc.push(...reduceHierarchyToListApplyPermissions(
        organization.children,
        {permissionsInherited: organization.permissions, fullPermissions},
        organization.id,
        organization.clientId,
        [...organization.hierarchyPath]
      ));
    }

    return acc;
  }, []);
}
