import {Component, Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import {BASE_URL, ROUTE_URLS, PUBLIC_ROUTE_URLS} from '../../../constants/routes';
import lazyWithRetry from '../../../utils/lazyWithRetry';

import Loading from '../Loadable/Loading';
import EntryExit from './EntryExit';
import SurveyResponsePage from '../../Survey/SurveyResponsePage';
import ProviderNameRedirectPage from '../../../containers/Login/ProviderNameRedirectPage/ProviderNameRedirectPage';
import CourseCompletion from './CourseCompletion/CourseCompletion';
import ReadReceiptPage from '../../ReadReceiptPage/ReadReceiptPage';

const LoadableAuthorizedApp = lazyWithRetry(() => import(
  /* webpackChunkName:'authorizedApp' */
  '../../../containers/Authorized/AuthorizedApp/AuthorizedApp'
));

const LoginRoute = lazyWithRetry(() => import(
  /* webpackChunkName:'loginPage' */
  './Routes/CustomLoginRoute'
));

export default class PublicRouter extends Component {

  render() {
    return (
      <Suspense fallback={<Loading/>}>
        <Switch>

          <Route path={BASE_URL}>
            <Switch>
              <Route exact path={ROUTE_URLS.LOGIN} component={LoginRoute}/>

              <Route exact path={PUBLIC_ROUTE_URLS.ENTRY_EXIT} component={EntryExit}/>

              <Route exact path={PUBLIC_ROUTE_URLS.COURSE_COMPLETION} component={CourseCompletion}/>

              <Route exact path={`${ROUTE_URLS.SURVEY}/:deliveryInstanceId`} component={SurveyResponsePage}/>

              <Route exact path={`${ROUTE_URLS.PROVIDER_LOGIN}/:providerName`} component={ProviderNameRedirectPage}/>

              <Route exact path={`${ROUTE_URLS.EMAIL_READ_RECEIPT}/:trackingId`} component={ReadReceiptPage}/>

              <Route component={LoadableAuthorizedApp}/>
            </Switch>
          </Route>

          <Redirect exact from="/" to={BASE_URL}/>
          <Route exact path={`/:client_url_string/email-read-receipt/:trackingId`} component={ReadReceiptPage}/>
          <Route exact path={`/:client_url_string/provider-login/:providerName`} component={ProviderNameRedirectPage}/>
          <Route path="/:client_url_string" component={LoginRoute}/>
        </Switch>
      </Suspense>
    );
  }
}
