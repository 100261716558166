import {PERSON_EXPORT_DATA_OPTIONS} from '../../../constants/persons';
import {REPORT_FORM_FIELDS} from '../../../components/Persons/ExportDetailsModal/ExportDetailsForm.utils';

export const FORM_REPORT_DEFAULT_VALUES = {
  namesIncluded: true,
  names: PERSON_EXPORT_DATA_OPTIONS.PRIMARY,
  accountInfo: PERSON_EXPORT_DATA_OPTIONS.ALL,
  emails: PERSON_EXPORT_DATA_OPTIONS.ALL,
  phones: PERSON_EXPORT_DATA_OPTIONS.ALL,
  emergencyContacts: PERSON_EXPORT_DATA_OPTIONS.ALL,
  identifiers: PERSON_EXPORT_DATA_OPTIONS.PRIMARY,
  addresses: PERSON_EXPORT_DATA_OPTIONS.ALL,
  mobileLocations: PERSON_EXPORT_DATA_OPTIONS.ALL,
  tripSegments: PERSON_EXPORT_DATA_OPTIONS.ALL
};

export function generateEditModeReportOptions(values) {
  if (!values) {
    return FORM_REPORT_DEFAULT_VALUES;
  }
  const res = Object.entries(values).reduce((previousValue, [key, value]) => {
    switch (key) {
      case REPORT_FORM_FIELDS.ACCOUNT_INFO:
      case REPORT_FORM_FIELDS.ADDRESSES:
      case REPORT_FORM_FIELDS.EMAILS:
      case REPORT_FORM_FIELDS.PHONES:
      case REPORT_FORM_FIELDS.EMERGENCY_CONTACT:
      case REPORT_FORM_FIELDS.IDENTIFIERS:
      case REPORT_FORM_FIELDS.NAMES:
      case REPORT_FORM_FIELDS.MOBILE_LOCATIONS:
      case REPORT_FORM_FIELDS.TRIP_SEGMENTS:
        return {
          ...previousValue,
          [[key] + 'Included']: !!value,
          [key]: !value ? FORM_REPORT_DEFAULT_VALUES?.[key] : value
        };
      case REPORT_FORM_FIELDS.INCLUDE_ORGS:
      case REPORT_FORM_FIELDS.INCLUDE_PERSON_TYPES:
      case REPORT_FORM_FIELDS.OPTIONAL_FIELDS:
      case REPORT_FORM_FIELDS.EXPATRIATIONS_INCLUDED:
      case REPORT_FORM_FIELDS.STOPOVERS_INCLUDED:
        return {...previousValue, [key]: value};
      default:
        return {...previousValue};
    }
  }, {});
  return {...FORM_REPORT_DEFAULT_VALUES, ...res};
}

