import waHttp from './wa-http';

export const baseApi = '/email/v1';
const baseURL = process.env.REACT_APP_GATEWAY_URL;

function sendReadReceipt({pathParams: {trackingId}}) {
  const url = `${baseApi}/messages/email/emailRequests/${trackingId}/readReceipt`;

  return waHttp.httpRequest({
    baseURL,
    url: url,
    method: 'POST'
  });
}

export default {
  sendReadReceipt
};